import React from "react";
import { render } from "react-dom";
import ROShare from "../NROShare";

const targets = document.getElementsByTagName("nwc-roshare");

Array.from(targets).forEach((elem) => {
  const includes = elem.getAttribute("includes");
  const portrait = elem.getAttribute("portrait");
  const sticky = elem.getAttribute("sticky");
  const title = elem.getAttribute("title");
  const url = elem.getAttribute("url");
  const trackingCategory = elem.getAttribute("trackingCategory");
  const colorTheme = elem.getAttribute("colorTheme");

  render(
    <ROShare
      includes={includes}
      portrait={portrait === "true"}
      sticky={sticky === "true"}
      title={title}
      url={url}
      trackingCategory={trackingCategory}
      colorTheme={colorTheme}
    />,
    elem
  );
});
