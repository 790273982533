import styled from "styled-components";

export type SocialButtonProps = {
  backgroundColor?: string;
  color?: string;
};

export type PortraitContainerProps = {
  portrait?: boolean;
  isSticky: boolean;
};

export const SocialButton = styled.button<SocialButtonProps>`
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid ${(p): string => p.color || "#333333"};
  cursor: pointer;
  margin: 4px;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  color: ${(p): string => p.color || "#333333"};
  background-color: white;
  transition: all 0.25s ease-out;
  &:hover,
  &:focus {
    background-color: ${(p): string => p.backgroundColor || "white"};
    border-color: ${(p): string => p.backgroundColor || "white"};
    color: white;
    outline: none;
  }
`;

export const PortraitContainer = styled.section<PortraitContainerProps>`
  display: flex;
  flex-direction: ${(p): string => (p.portrait ? "column" : "row")};

  ${({ isSticky }: PortraitContainerProps): string =>
    isSticky &&
    `
      position: fixed;
      top: 100px;
    `}

  @media print {
    display: none;
  }
`;
