import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import getTracker from "~/utils/track";
import { isMobileDevice } from "~/utils/isMobileDevice";

import { SocialButton, PortraitContainer } from "./support/NROShare.styles";

type ROShareProps = {
  includes?: string;
  portrait?: boolean;
  sticky?: boolean;
  title?: string;
  url?: string;
  description?: string;
  imageUrl?: string;
  trackingCategory?: string;
  colorTheme?: string;
};

enum ActionType {
  OpenLink = "openLink",
  Print = "print",
}

const handleTracking = (service: string, trackingCategory: string): void =>
  getTracker({
    category: trackingCategory,
    action: "Share",
    label: service,
  });

const handleClick = (
  service: string,
  url: string,
  action: ActionType,
  trackingCategory: string
): void => {
  handleTracking(service, trackingCategory);
  // Determine whether to open link or print
  if (action === ActionType.Print) {
    window.print();
  } else if (action === ActionType.OpenLink) {
    const win = window.open(url, "_blank");
    win.focus();
  }
};

const getPageMetaValue = (
  name: string,
  value: string,
  defaultValue: string
): string => {
  return (
    value ||
    document.querySelector<HTMLMetaElement>(`meta[property='og:${name}']`)
      ?.content ||
    defaultValue
  );
};

const getArticleUrl = (url: string): string =>
  getPageMetaValue("url", url, window.location.href);

const getArticleTitle = (title: string): string =>
  getPageMetaValue("title", title, "Welcome to ReachOut.com");

const getArticleDescription = (description: string): string =>
  getPageMetaValue("description", description, "");

const getArticleImageUrl = (imageUrl: string): string =>
  getPageMetaValue("image", imageUrl, "");

const sharers = [
  {
    service: "facebook",
    action: ActionType.OpenLink,
    urlBuilder: (title: string, url: string): string =>
      `https://www.facebook.com/sharer/sharer.php?quote=${title}&u=${url}`,
    icon: "rfa-facebook-f",
    color: "#3b5998",
    label: "Share on Facebook",
  },
  {
    service: "twitter",
    action: ActionType.OpenLink,
    urlBuilder: (title: string, url: string): string =>
      `https://twitter.com/intent/tweet?text=${title}&url=${url}`,
    icon: "rfa-twitter",
    color: "#55acee",
    label: "Share on Twitter",
  },
  {
    service: "reddit",
    action: ActionType.OpenLink,
    urlBuilder: (title: string, url: string): string =>
      `https://reddit.com/submit?title=${title}&url=${url}`,
    icon: "rfa-reddit-alien",
    color: "#ff5700",
    label: "Share on Reddit",
  },
  {
    service: "whatsapp",
    action: ActionType.OpenLink,
    urlBuilder: (title: string, url: string): string =>
      `whatsapp://send?text=${title}%0a%0a${url}`,
    icon: "rfa-whatsapp-fa",
    color: "#25d366",
    label: "Share on WhatsApp",
  },
  {
    service: "email",
    action: ActionType.OpenLink,
    urlBuilder: (title: string, url: string): string =>
      `mailto:?body=${title}%0a%0a${url}`,
    icon: "rfa-envelope",
    label: "Share via email",
    color: "#333",
  },
  {
    service: "print",
    action: ActionType.Print,
    icon: "rfa-ro-printer",
    label: "Print",
    color: "#333",
  },
  {
    service: "pinterest",
    action: ActionType.OpenLink,
    urlBuilder: (
      title: string,
      url: string,
      description: string,
      imageUrl: string
    ): string =>
      `https://pinterest.com/pin/create/button/?url=${url}&media=${imageUrl}&descrption=${description}`,
    icon: "rfa-pinterest",
    color: "#bd081c",
    label: "Share on Pinterest",
  },
  {
    service: "linkedin",
    action: ActionType.OpenLink,
    urlBuilder: (title: string, url: string): string =>
      `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
    icon: "rfa-linkedin-in",
    color: "#0077b5",
    label: "Share on Linked In",
  },
];

const ROShare: React.FC<ROShareProps> = ({
  includes = "",
  portrait,
  sticky,
  title,
  url,
  description,
  imageUrl,
  trackingCategory = "ROShare",
  colorTheme,
}) => {
  const portraitContainerRef = useRef<HTMLElement>(null);
  const [isSticky, setSticky] = useState(false);

  const encodedTitle = encodeURIComponent(getArticleTitle(title));
  const encodedUrl = encodeURIComponent(getArticleUrl(url));
  const encodedDescription = encodeURIComponent(
    getArticleDescription(description)
  );
  const encodedImageUrl = encodeURIComponent(getArticleImageUrl(imageUrl));
  const chosenSharers = includes.replace(/ /g, "").split(",");

  const includedSharers = chosenSharers
    .map((s) => {
      if (s === "whatsapp" && !isMobileDevice()) return null;
      return sharers.find((x) => x.service === s);
    })
    .filter((x) => x != null);

  // Moves the buttons around based on scrolling
  const handleScroll = (): void => {
    const target = portraitContainerRef.current;
    if (!target) return;
    const rect = target.getBoundingClientRect();

    setSticky(window.pageYOffset >= rect.top);
  };

  useEffect(() => {
    if (sticky == true) {
      window.addEventListener("scroll", handleScroll);
    }
    return window.removeEventListener("scroll", () => handleScroll);
  }, []);

  const buttons = includedSharers.map((sharer) => {
    const { service, action, icon, urlBuilder, color, label } = sharer;
    return (
      // SocialButton takes in the service type (reddit, fb, twitter.. etc) and determines what color it should display based on that
      <SocialButton
        key={service}
        id={`roshare-${service}`}
        onClick={(): void =>
          handleClick(
            service,
            urlBuilder
              ? urlBuilder(
                  encodedTitle,
                  encodedUrl,
                  encodedDescription,
                  encodedImageUrl
                )
              : null,
            action,
            trackingCategory
          )
        }
        backgroundColor={color}
        color={colorTheme}
        aria-label={label}
      >
        <i className={`rfa ${icon}`}></i>
      </SocialButton>
    );
  });

  return (
    <PortraitContainer
      ref={portraitContainerRef}
      portrait={portrait}
      isSticky={isSticky}
    >
      {buttons}
    </PortraitContainer>
  );
};

ROShare.propTypes = {
  includes: PropTypes.string,
  portrait: PropTypes.bool,
  sticky: PropTypes.bool,
  title: PropTypes.string,
  url: PropTypes.string,
  trackingCategory: PropTypes.string,
  colorTheme: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
};

export default ROShare;
